const backgrounds = [
    'bg-[url("./assets/galaxy1.jpg")]',
    'bg-[url("./assets/galaxy2.jpg")]',
    'bg-[url("./assets/galaxy3.jpg")]',
    'bg-[url("./assets/galaxy4.jpg")]',
    'bg-[url("./assets/galaxy5.jpg")]',
    'bg-[url("./assets/galaxy6.jpg")]',
    'bg-[url("./assets/galaxy7.jpg")]',
    'bg-[url("./assets/galaxy8.jpg")]',
    'bg-[url("./assets/galaxy9.jpg")]',
    'bg-[url("./assets/galaxy10.jpg")]',    
    'bg-[url("./assets/galaxy11.jpg")]',    
    'bg-[url("./assets/galaxy12.jpg")]',    
    'bg-[url("./assets/galaxy13.jpg")]',    
    'bg-[url("./assets/galaxy14.jpg")]',    
    'bg-[url("./assets/galaxy15.jpg")]',    
    'bg-[url("./assets/galaxy16.jpg")]',    
    'bg-[url("./assets/galaxy17.jpg")]',    
    'bg-[url("./assets/galaxy18.jpg")]',    
    'bg-[url("./assets/galaxy19.jpg")]',    
    'bg-[url("./assets/galaxy20.jpg")]',    
    'bg-[url("./assets/galaxy21.jpg")]',    
    'bg-[url("./assets/galaxy22.jpg")]',    
    'bg-[url("./assets/galaxy23.jpg")]',    
    'bg-[url("./assets/galaxy24.jpg")]',    
    'bg-[url("./assets/galaxy25.jpg")]',    
    'bg-[url("./assets/galaxy26.jpg")]',    
    'bg-[url("./assets/galaxy27.jpg")]',    
    'bg-[url("./assets/galaxy28.jpg")]',    
];

export default backgrounds;